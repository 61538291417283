<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <div class="relative ">
      <section class="px-4 text-2xl font-extrabold text-center mt-8">
        Trigger Payment
      </section>
      <p class="text-center  font-semibold mt-2">
        Invoice Id: #{{ $truncate(actionData.invoiceId, -5) }}
      </p>

      <section class="px-4 py-2 ">
        <AppInput
          v-model="form.amount"
          rules="required"
          :label="`Amount (${actionData.symbol})`"
          type="number"
          min="0"
          step="0.01"
          :name="`Amount (${actionData.symbol})`"
          infoDescription="Enter the amount to charge the rider's card"
        />
      </section>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton variant="secondary" :class="`w-1/2`" @click="closeModal()">
          Cancel
        </AppButton>

        <AppButton
          :class="`w-1/2`"
          :isLoading="isLoading"
          @click="handleTrigger()"
        >
          Trigger
        </AppButton>
      </section>
    </div>
  </t-modal>
</template>

<script>
import { xMan } from '@/utils'
import { useEndpoints } from '@/composables'
export default {
  name: 'AmountTriggerAction',
  components: {},

  props: {
    id: {
      type: String,
      required: true,
    },

    actionData: {
      type: Object,
      default: () => ({ invoiceId: '', amount: 0, symbol: '$' }),
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    form: {
      amount: null,
    },
  }),
  watch: {
    actionData: {
      deep: true,
      immediate: true,
      handler(data) {
        this.form.amount = data.amount
      },
    },
  },

  methods: {
    closeModal() {
      this.$modal.hide(`${this.id}`)
      this.clearData()
    },

    async handleTrigger() {
      if (!this.form.amount) {
        this.$notify(
          {
            group: 'generic',
            type: 'Default',
            title: 'Alert',
            text: `Please try with an amount`,
          },
          1500
        )
        return
      }

      this.isLoading = true
      const url = useEndpoints.invoice.triggerNegativeBalanceResolver(
        this.actionData.invoiceId
      )
      const data = new xMan(this.form).toFormData()
      await this.$http
        .post(url, data)
        .then(({ data }) => {
          this.$emit('refresh-page')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${data.detail}`,
            },
            5000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${
                err.response.data
                  ? err.response.data.detail
                    ? err.response.data.detail
                    : err.response.data
                  : 'Failed to trigger payment'
              }`,
            },
            5000
          )
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
          this.clearData()
        })
    },
    clearData() {
      this.form.amount = null
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
